.farm-title {
    padding: 0;
}

.lable {
    margin-left: 1em !important;
}

.switch-pig.ant-switch {
    margin-top: 10px;
}

.switch-pig.ant-switch-checked {
    background-color: #001cee18;
}

// .makeStyles-modalContentCreate-8 {
//     width: 40% !important;
// }
.gm-style-mtc {
    display: none !important;
}

.pac-container.pac-logo.hdpi {
    z-index: 99999
}