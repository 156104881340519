@import 'src/public/css/variables.scss';

.main-arrow {
    position: absolute;
    z-index: 1000;

    .hidden-arrow {
        display: none;
    }

    .up-arrow {
        display: grid;
    }

    .down-arrow {
        display: grid;
    }

    .left-arrow {
        display: block;
    }

    .right-arrow {
        display: block;
    }
}