#wrapper-steps-progress {
    display: grid;
    width: 100%;
    align-items: center;
    align-content: center;
    grid-template-columns: repeat(3, 1fr);

    .success-step * {
        border-color: lightgreen !important;
        color: lightgreen !important;
        transition: 0.5s linear;
    }

    .step-progress {
        display: flex;
        align-items: center;
        align-content: center;

        .on-progress {
            width: 25px;
            border-radius: 50%;
            border: 1px solid lightgray;
            display: flex;
            justify-content: center;
            margin-right: 12px;
            font-weight: bold;
        }

        .progress {
            width: 79%;
            border-bottom: 1px solid lightgray;
            text-align: center;
            font-size: 13px;
            margin-top: -19px;

            .progress-title {
                opacity: 0;
                transition: 0.5s linear;
            }

            .progress-title-active {
                opacity: 1 !important;
            }

            &:hover {
                .progress-title {
                    opacity: 1;
                }
            }
        }
    }
}

.wrapper-action {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    left: 0;
    padding: 12px 32px;
}

.wrapper-action.hide {
    // text-align: end;
    justify-content: end;

    .btn.back {
        display: none;
    }
}

.step-content {
    margin: 24px 0;
}

#detail-supplier {
    width: 100%;
    background-color: rgba(223, 219, 219, 0.377);
    border-radius: 10px;
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 24px;

    #supplier-information {
        .information {
            margin-top: 6px;

            label {
                font-weight: bold;
            }

            span {
                color: gray;
            }
        }
    }

    .consume-title {
        font-weight: bold;
    }
}

#detail-info {
    background-color: rgba(223, 219, 219, 0.377);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 12px;
    border-radius: 5px;
    grid-gap: 24px;

    .info {
        display: grid;
        grid-template-rows: 2fr 1fr;

        .info-title {
            font-weight: bold;
            font-size: 16px;
            display: block;
            margin-bottom: 12px;
        }
    }
}

#pen-food-consume-detail {
    margin-top: 24px;

    h3 {
        font-weight: bold;
        font-size: 16px;
    }
}

.ordered-input__change-order {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100px;
}

.modal-food-consume {
    #wrapper-log .log-box .log-box__content {
        height: 400px !important;
        overflow: auto;
    }
}

.modal-food-consume {
    .ant-modal-body {
        overflow-y: scroll;
        height: 400px !important;
    }

    .form-control {
        width: 20em;
    }

    .form-content {
        display: flex;
        justify-content: space-between;
        gap: 3em;

        .form-content-right {
            width: 80%;
        }
    }

    .lst-export-food {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: .5em;

        .lst-food {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .input-number {
                width: 4em;
            }
        }
    }

    b {
        font-size: 1.2em;
    }

    .summit-btn:disabled {
        background-color: #d9d9d9 !important;
        border-color: #d9d9d9 !important;
        color: #8c8c8c !important;
    }
}

@media (max-width: 1000px) {
    .ant-modal-root {
        .ant-modal-wrap {
            top: 0px !important;
        }
    }
}