#not-found {
    // background-color: #E8F8FF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2em;
    // height: 100vh;

    #myVideo {
        // min-width: 100%;
        // min-height: 100%;
    }

    #not-found__text {
        font-size: 20px;
        font-weight: bold;
        z-index: 100;
    }
}