@import 'src/public/css/variables.scss';

#wrapper-header {
    height: 88px;
    display: grid;
    // background-color: #E8F8FF;
    position: relative;
    z-index: 100;
    width: 100%;

    #header {
        display: grid;
        grid-template-columns: 20% 80%;
        width: auto;
        margin: 0 3em;
    }

    #header-logo {
        display: flex;
        align-items: center;
    }

    #header-menu {
        display: grid;
        width: 100%;
        grid-template-columns: 73% 28%;

        .center-menu {
            margin: 0 auto;

            li {
                margin: 0 12px;

                .underline-menu {
                    white-space: nowrap;
                    font-size: 16px;
                }

                .underline-menu.active {
                    transition: all 0.3s ease-in-out;
                    // border-bottom: 2px solid #ff0000;
                    color: #ff0000 !important;
                }
            }
        }

        .center-menu.Admin {
            li {
                margin: 0 2em;
            }
        }

        #log-menu {
            justify-self: flex-end;
            display: flex;
            font-size: 14px;
            align-items: center;

            .login-btn {
                background-color: #FFECEE;
                color: #001AEE;
            }

            .register-btn {
                background-color: #001AEE;
                color: #fff;
                margin-left: 32px;
            }

            .notification-menu {
                margin-right: 1em;
                cursor: pointer;
            }

            .notification-menu-bell.active {
                .ant-badge {
                    opacity: .7;
                }
            }

            .ant-dropdown-open {}

            .bell-icon {
                font-size: 1.5em;
                color: #18191F;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
            }

            .bell-icon.active {
                color: #001AEE;
            }

            #user {
                position: relative;
                cursor: pointer;

                img {
                    height: 2.5em;
                    width: 2.5em;
                    border-radius: 50%;
                    object-fit: fill;
                }

                #log-menu {
                    position: absolute;
                    right: 0;
                    background-color: #fff;
                    z-index: 100;
                    padding: 12px 0;
                    border: 1px solid lightblue;
                    border-radius: 5px;
                    display: none;
                    width: 140px;

                    ul {
                        display: block;
                        min-width: 120px;

                        li {
                            padding: 6px 24px;

                            * {
                                font-size: 14px;
                                color: $textColor;
                            }

                            &:hover {
                                background-color: lightgrey;
                            }
                        }
                    }
                }

                &:hover {
                    #log-menu {
                        display: block;
                    }
                }
            }
        }
    }
}

.div-select-header-farm {
    margin-right: 2em;
}

@media (min-width: 1280px) {

    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
        padding: 0 1px !important;
    }

}

@media (max-width: 1280px) {
    #wrapper-header {
        #header {}

        #header-logo {}

        #header-menu {
            .center-menu {
                li {
                    margin: 0;

                    .underline-menu {
                        font-size: 14px;
                    }
                }
            }

            #log-menu {
                .login-btn {}

                .register-btn {}

                #user {
                    img {}

                    #log-menu {
                        ul {
                            li {
                                * {}

                                &:hover {}
                            }
                        }
                    }

                    &:hover {
                        #log-menu {}
                    }
                }
            }
        }
    }

    .select-header-farm {
        width: 10em !important;
    }

    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
        padding: 0px !important;
    }
}

.ant-dropdown {
    width: 400px;

    .ant-dropdown-menu {
        padding: 1em;
        gap: 1em;
        display: flex;
        flex-direction: column;
        height: 510px;
        border-radius: 16px;
        // overflow-y: auto;

        .title-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;

            gap: 1em;
            font-size: 1.25em;

            .title-text {
                color: #000;
                font-family: Inter;
                font-style: normal;
                font-weight: 700;
            }
        }

        .action-groud {
            display: flex;
            // align-items: center;
            gap: .5em;
            // overflow-x: scroll;
            // width: 100%;
            // height: 300px;

            .ant-btn {
                border-radius: 30px;
                border: 1px solid #001AEE;
                color: #001AEE;
            }

            .ant-btn.action {
                background-color: #001AEE !important;
                color: #fff !important;
            }
        }

        .view-all-notification {
            display: flex;
            flex-direction: column;
            gap: .5em;
            overflow: auto;

            .title-view-all {
                font-size: 1.1em;
                color: #000;
                font-family: Inter;
                font-style: normal;
                font-weight: 700;
            }

            .view-all-content {
                display: flex;
                flex-direction: column;
                gap: .5em;

                .view-all-item {
                    display: flex;
                    align-items: center;
                    gap: 1em;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    border-radius: 10px;
                    padding: .25em;

                    .avatar {
                        img {
                            padding: .5em;
                            background: #FFECF0;
                            border-radius: 50%;
                            width: 4em;
                        }
                    }

                    .avatar-temp {
                        img {
                            padding: .5em;
                            border-radius: 50%;
                            width: 4em;
                        }
                    }

                    .content {
                        display: flex;
                        flex-direction: column;
                        width: 400px;

                        .title {
                            color: #2c2c2c;
                            font-size: 17px;
                            font-family: Inter;
                            font-style: normal;
                            font-weight: 700;
                        }

                        .description {
                            color: #636363;
                            font-size: 13px;
                            font-family: Inter;
                            font-style: normal;
                            font-weight: 700;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            /* number of lines to show */
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }

                        .time {
                            color: #001AEE;
                            font-size: 13px;
                            font-weight: 700;
                        }
                    }

                    .icon {
                        color: #001AEE;
                        font-size: 2em;
                        font-weight: 400;
                    }

                    .icon::after {
                        content: "•"
                    }

                }

                .view-all-item:hover {
                    background-color: #001cee18;
                }

                .view-all-item.active {
                    .content {
                        .title {
                            color: #686a6e;
                        }

                        .description,
                        .time {
                            color: #8c8e90;
                        }
                    }

                    .icon {
                        color: #93939380;
                        font-size: 2em;
                        font-weight: 400;
                    }

                    .icon::after {
                        content: "⋮"
                    }
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #001cee76;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #001cee18;
    }
}

.menu-lst {
    .ant-menu-submenu-title {
        font-size: 18px;
    }
}

.makeStyles-modal-10 {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.makeStyles-modalContentCreate-11 {
    width: 70%;
    background-color: white;
}

/*# sourceMappingURL=core.css.map */
.ant-menu-horizontal {
    border: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
    color: #001AEE !important;
}

.ant-menu-overflow-item:hover {
    color: #001AEE !important;
}

.ant-menu-light.ant-menu-item:hover,
.ant-menu-light.ant-menu-item-active,
.ant-menu-light.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light.ant-menu-submenu-active,
.ant-menu-light.ant-menu-submenu-title:hover {
    color: #001AEE !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
    color: #001AEE !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: #001AEE !important;
}

.ant-menu-item a:hover {
    color: #001AEE !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #001cee18 !important;
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
    // background-color: #ffe3e9 !important;
    color: #001AEE !important;
}

.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
    color: #001AEE !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
    background-color: white !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
    border-bottom: 2px solid #001AEE !important;
}

.menu-lst-admin {
    .sub-title-report {
        .-title-content {
            font-size: 0.5em;
            font-weight: 400;
        }
    }

    .-title-content {
        font-size: 0.9em;
        font-weight: 500;
    }
}


.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child:hover {
    transition: all 0.3s ease-in-out;

    .ant-menu-title-content {
        border-bottom: 2px solid #001AEE !important;
    }
}

.ant-menu-title-content {
    font-size: 1.1em;
}

.select-farm {
    .ant-select-item-option-content {
        font-size: 1em;
    }
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    border-radius: 4px !important;
}

.noti-image {
    position: absolute;
    right: 23%;
    bottom: 60px;
}