.main-tag-pig-pen {
    margin: 0 3em;

    .main-breadcrumb {
        display: flex;
        justify-content: space-between;

        .ant-breadcrumb {
            font-size: 1.25em;
            margin-bottom: 1em;
        }

        button {
            background: #001AEE;
            border-radius: 6px;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
        }

        button:hover {
            scale: 1.1;
            border: 1px solid #001AEE;
        }

    }

    .table-tag-pig {
        display: flex;
        justify-content: space-between;
        gap: 1em;

        .title-table {
            display: flex;
            justify-content: space-between;
            color: var(--content-content-body, #4E4E4E);
            /* Headline medium */
            font-size: 24px;
            font-family: Inter;
            font-weight: 500;
            padding-bottom: .5em;


        }

        .ant-space.ant-space-horizontal.ant-space-align-center {
            gap: 0 !important;
        }

        .table-list-pig,
        .table-temp-pig,
        .table-sick-pig {
            width: 65vw !important;

            .ant-table-thead>tr>th,
            .ant-table-tbody>tr>td,
            .ant-table tfoot>tr>th,
            .ant-table tfoot>tr>td {
                padding: 10px;

                .warning {
                    color: #001AEE;
                }
            }

            .MuiButtonBase-root.MuiIconButton-root {
                padding: 0 10px
            }

            .MuiButtonBase-root.MuiIconButton-root.active {
                color: #001AEE;
            }

            .MuiButtonBase-root.MuiIconButton-root:hover {
                background-color: transparent;
            }
        }

        .ant-list {
            .ant-list-items {
                height: 550px;
                overflow: auto;

                #active {
                    background: #FFECF0 !important;
                }

                #active-button {
                    color: #001AEE !important;
                }

                .ant-list-item {
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;
                    padding: 10px;
                    padding-bottom: 0;
                    border-radius: 10px;
                    position: relative;
                    justify-content: baseline;
                    padding-right: 100px;

                    .list-pig-item {
                        display: flex;
                        align-items: center;
                        gap: 1em;
                        font-size: 16px;
                        font-family: Lexend Deca;
                        font-weight: 500;
                        color: #4E4E4E;
                        justify-content: space-between;

                        .item-ordinalNumber {
                            width: 15px;
                        }

                        .item-image-pig {
                            text-align: center;

                            img {
                                padding: 10px;
                                border-radius: 10px;
                                background: #FBD1DB;
                            }
                        }

                        .item-content {

                            .item-temp,
                            .item-status {
                                font-size: 14px;
                            }
                        }

                        .item-icon {
                            position: absolute;
                            right: 10px;
                        }
                    }

                }

                .ant-list-item:hover {
                    background: #ffecf04b;
                }
            }


        }
    }


    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ffaabc;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(255, 125, 125);
    }

}

.table-temp .ant-table {
    border-radius: 10px;
    /* Customize the border radius value */
}

.table-temp .ant-table-thead>tr>th:first-child {
    border-top-left-radius: 10px;
}

.table-temp .ant-table-thead>tr>th:last-child {
    border-top-right-radius: 10px;
}

.table-temp .ant-table-tbody>tr:last-child>td:first-child {
    border-bottom-left-radius: 10px;
}

.table-temp .ant-table-tbody>tr:last-child>td:last-child {
    border-bottom-right-radius: 10px;
}

.ant-select-item-empty {
    color: #7b7b7b;
    cursor: pointer;
}

.ant-select-item-empty:hover {
    background-color: #001AEE;
    color: white;
}