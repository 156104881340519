.ordered-input {
    display: flex;
    justify-content: center;

    input {
        width: 50px;
        outline: none;
        border: none;
        border-bottom: 1px solid lightgrey;
        margin-right: 6px;
        padding: 0 3px;
    }

    .error-data-input {
        border-color: red !important;
        color: red !important;
    }
}

#supplier-select {
    padding: 6px 3px;
    border: none;
    border-bottom: 1px solid lightgray;
    outline: none;
    border-radius: 0;
}

.ordered-input__change-order {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100px;
}

.error-data {
    display: block;
    text-align: center;
    color: red;
}

.food-order-modal {

    #wrapper-log {
        .log-box {
            .log-box__content {
                height: 500px;
                overflow-y: scroll;
            }
        }
    }
}

#wrapper-log .log-box .log-box__content .log-form .log-field .log-btn:disabled {
    background-color: #ccc;
    border-color: #ccc;
    color: #fff;
}

.ant-modal.delete-modal {
    margin-top: 1em;
    margin-bottom: 1em;

    .ant-modal-content {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
}

.btn-submit-form:disabled {
    background-color: #ccc !important;
    border-color: #ccc !important;
    color: #fff !important;
}