%selectionBox {
    border: 1px solid lightgray;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
}

#pig-banner {
    width: 100%;
    background: #e8f8ff;
    position: relative;
    padding: 64px 0;
    box-sizing: border-box;

    #identity-btn {
        background: #00b1ff;
        border-radius: 6px;
        color: white;
        width: 200px;
        margin: 32px auto 0 auto;
    }

    h1 {
        color: #001AEE;
    }

    #pig-banner__content {
        text-align: center;
    }
}

#wrapper-confirm-identity {
    display: grid;
    grid-template-columns: 20% 80%;

    .selection-box {
        @extend %selectionBox;
    }

    #selection {
        background-color: #001cee18;
        overflow: scroll;
        height: 600px;

        .test {
            display: grid;

            label {
                @extend %selectionBox;
            }
        }
    }
}

#detail-main {
    display: flex;
    justify-content: center;
    // align-items: center;
    // margin-top: 10em;
    // margin: 2em auto;
    padding-top: 0em;
    // padding: 5em 0;
    box-sizing: border-box;
    width: 100%;
    // height: 600px;
    overflow-y: auto;

    .detail-content {
        margin-top: 2.5em;
    }

    #header-detail {
        margin-top: -3%;
        display: flex;

        .detail-avatar {
            width: 15%;
            margin-right: 10px;
            object-fit: cover;
            border-radius: 50%;
        }

        #desc {
            display: grid;
            align-items: center;

            #pig-id {
                font-weight: bold;
                font-size: 22px;
            }

            #health-status {
                font-weight: bold;

                .baka {
                    margin-left: 5px;
                }

                .warning {
                    margin-left: 5px;
                    color: #001AEE;
                }
            }


            #pig-info {
                display: flex;

                .desc-content {
                    margin-right: 24px;

                    label {
                        color: #979797;
                    }

                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    #information {
        margin-top: 24px;

        .info-content {
            margin-bottom: 16px;

            .info-content__title {
                font-weight: bold;
            }
        }
    }

    #activity-day__report {
        margin-top: 4%;

        #activity-title {
            color: #979797;
            font-weight: bold;
        }

        #wrapper-activity {
            .activity {
                display: grid;
                grid-template-columns: 33% 17% 25% 25%;
                color: #18191f;
                margin-top: 12px;

                .activity-name {
                    display: grid;
                    grid-template-columns: 42px auto;
                    align-items: center;

                    .activity-icon {
                        margin-right: 12px;
                        width: 21px;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    #wrapper-chart {
        display: flex;
        justify-content: center;
        // text-align: center;
        margin: 22px 0;
        // padding-bottom: 22px;
        width: 320px;

        #time-report {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-bottom: 24px;

            #time-report__input {
                border: 1px solid #001cee18;
                box-sizing: border-box;
                border-radius: 6px;
                padding-left: 11px;
                margin-left: 11px;
            }
        }
    }
}