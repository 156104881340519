:root {
    --primary-color: #185ee0;
    --secondary-color: #e6eef9;
}

#auth-modal-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    #auth-modal__cancel {
        margin-left: auto;
        width: 25px;
    }
}

#auth-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin-top: 55px;

    #auth-left {
        display: grid;
        justify-content: center;
        text-align: center;
        grid-gap: 24px;
        grid-template-rows: 20% 80%;

        h1 {
            font-weight: bold;
            font-size: 20px;
        }

        #pig-wrapper {
            width: 300px;
            height: 300px;
            margin: 0 auto;

            img {
                width: 250px;
            }
        }
    }

    .wrapper-form {
        display: grid;
        min-height: 350px;
        align-items: center;
    }

    #wrapper-form {
        background: #FFFFFF;
        box-shadow: 0px 0px 14px 8px rgba(24, 25, 31, 0.07);
        border-radius: 20px;
        width: 80%;
        display: block;
        position: relative;
        margin: 0 auto;
        padding: 34px;
        box-sizing: border-box;


        #wrapper-toggle {
            border-radius: 10px;
            background: #FFECEE;
        }

        .container {
            // position: absolute;
            display: flex;
            // align-items: center;
            // justify-content: center;
            margin-bottom: 24px;
        }

        .tabs {
            display: flex;
            position: relative;
            background-color: #FFECEE;
            box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
            border-radius: 99px;

            * {
                z-index: 2;
            }
        }

        input[type="radio"] {
            display: none;
        }

        .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            width: 125px;
            font-weight: 500;
            border-radius: 99px;
            cursor: pointer;
            transition: color 0.15s ease-in;
        }

        .notification {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            margin-left: 0.75rem;
            border-radius: 50%;
            background-color: var(--secondary-color);
            transition: 0.15s ease-in;
        }

        input[type="radio"] {
            &:checked {
                &+label {
                    color: #fff !important;
                    font-weight: bold !important;

                    &>.notification {
                        background-color: var(--primary-color);
                        color: #fff;
                    }
                }
            }
        }

        input[id="login-form"] {
            &:checked {
                &~.glider {
                    transform: translateX(0);
                }
            }
        }

        input[id="register-form"] {
            &:checked {
                &~.glider {
                    transform: translateX(100%);
                }
            }
        }

        .glider {
            position: absolute;
            display: flex;
            height: 42px;
            width: 125px;
            background-color: #001AEE;
            z-index: 1;
            border-radius: 99px;
            transition: 0.25s ease-out;
        }


        .auth-field__col-2 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 24px;
        }

        .auth-field {
            margin-bottom: 12px;

            .auth-input {
                border: none;
                border-bottom: 1px solid lightgray;
                width: 100%;
                padding: 16px 8px;
                box-sizing: border-box;
            }

            .auth-btn {
                width: 100%;
                background-color: #FFECEE;
                border: none;
                font-weight: bold;
                margin-top: 36px;
            }

            .auth-btn:hover {
                width: 100%;
                background-color: #001AEE;
                color: #FFFFFF;
                border: none;
                font-weight: bold;
                margin-top: 36px;
            }
        }


    }
}

.registerSuccess {
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    flex-direction: 'column';
    font-weight: 500;
    font-size: '16px';
    color: '#18191F';
}

.ant-row.ant-form-item.form-item-email {
    display: grid;
    grid-template-columns: .3fr 1fr;
    grid-template-rows: 1fr;
    // gap: 4em;
}