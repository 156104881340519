.sick-pig-main {
    font-family: 'Inter';
    font-style: normal;

    .ant-breadcrumb {
        margin: 1em 9vw;
    }

    .button-sick-pig {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 9vw;
        margin-top: 1em;

        .ant-input-group-wrapper {
            width: 20em;

            .ant-input-affix-wrapper:hover,
            .ant-input-group-wrapper:focus {
                color: #001AEE !important;
                border-color: #001AEE !important;
                background: #FFFFFF !important;
                outline: none !important;
                box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
            }

        }

        button {
            background: #001AEE;
            border-radius: 6px;
            font-weight: 500;
            font-size: 1em;
            color: #FFFFFF;
        }

        button:hover {
            scale: 1.1;
            border: 1px solid #001AEE;
        }


    }

    .button-sick-pig.dianosis {
        justify-content: center;

        .ant-transfer {
            .ant-transfer-list {
                width: 25em;
                height: 50vh;

                .ant-transfer-list-header {


                    .ant-checkbox-wrapper.ant-transfer-list-checkbox,
                    .anticon.anticon-down.ant-dropdown-trigger.ant-transfer-list-header-dropdown,
                    .ant-transfer-list-header-selected {
                        display: none;
                    }

                    .ant-transfer-list-header-title {
                        flex: none;
                        font-size: 1em;
                        font-weight: 700;
                    }

                }

                .ant-transfer-list-body {
                    .ant-transfer-list-content {
                        .ant-transfer-list-content-item-checked {
                            background-color: #ff86a2;
                            color: white;
                            // margin-left: 10px;
                        }

                        .ant-checkbox-checked {
                            .ant-checkbox-inner {
                                background-color: #ff144b;
                                border-color: #ff144b;
                            }
                        }

                        .ant-transfer-list-content-item-text {
                            // padding-left: 10px;
                        }
                    }
                }
            }

            .ant-checkbox-wrapper.ant-transfer-list-checkbox {
                display: none;
            }
        }
    }

    .button-dianosis {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2em 0;

        button {
            background: #001AEE;
            border-radius: 6px;
            font-weight: 500;
            font-size: 1em;
            color: #FFFFFF;
        }

        button:hover {
            scale: 1.1;
            border: 1px solid #001AEE;
        }
    }

    .table-sick-pig {
        margin: 1em 9vw;

        .ant-table-wrapper {
            border-radius: 6px;

            .ant-table-thead {
                tr {
                    th {
                        font-weight: 700;
                        text-align: center;
                    }

                    .ant-table-cell:nth-child(5) {
                        width: 15em;
                    }

                    .ant-table-cell:nth-child(6) {
                        width: 15em;
                    }

                    .ant-table-cell:nth-child(7) {
                        width: 10em;
                    }

                    .ant-table-cell:nth-child(8) {
                        width: 10em;
                    }
                }
            }

            .ant-table-tbody {
                .ant-table-cell {
                    text-align: center;

                    .ant-space-item {
                        .warning {
                            color: #001AEE;
                        }
                    }
                }
            }
        }

        .even-row {
            background-color: #ffecee;
        }

        .odd-row {
            background-color: #ffffff;
        }

        th {
            background-color: #ffc8d4;
        }


    }


}

.anticon.anticon-check {
    color: white !important;
}

.ant-select-item-option-selected {

    .ant-select-item-option-content {
        color: white !important;
    }
}

.ant-btn:hover,
.ant-btn:focus {
    color: #001AEE !important;
    border-color: #001AEE !important;
    background: #FFFFFF !important;
}

.ant-space.ant-space-horizontal.ant-space-align-center {
    gap: 1em !important;
}

.ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center !important;

    .ant-pagination-item {
        border-radius: 10px;
    }

    .ant-pagination-item-link {
        border-radius: 10px !important;
    }

    .ant-pagination-item-active,
    .ant-pagination-item-active:focus-visible,
    .ant-pagination-item-active:hover,
    .ant-pagination-item:focus-visible,
    .ant-pagination-item:hover {
        border-color: #001AEE;

        a {
            color: #001AEE;
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        .ant-pagination-item-link:hover {
            border-color: #001AEE;
            color: #001AEE;
        }
    }

    .anticon svg {
        margin-bottom: 6px;
    }
}

.ant-spin {
    .ant-spin-dot-item {
        background-color: #001AEE;
    }

    .ant-spin-text {
        color: #001AEE;
    }
}

.ant-modal-root {
    .ant-modal-wrap {

        .ant-modal {
            .ant-modal-content {
                height: 100% !important;
                max-height: 100% !important;
            }

            .ant-modal-body {

                .action-btn {
                    display: flex;
                    justify-content: flex-end;
                    gap: 1em;

                    .cancel-btn {
                        color: rgba(0, 0, 0, 0.85) !important;
                        background-color: white !important;
                        border: 1px solid rgba(0, 0, 0, 0.85) !important;
                    }

                    .cancel-btn:hover {
                        color: #001AEE !important;
                        background-color: white !important;
                        border: 1px solid #001AEE !important;
                    }
                }


            }
        }

        .delete-modal {
            .ant-modal-body {
                display: flex;
                flex-direction: column;
                gap: 1em;


            }

        }
    }



}

.ant-slider-handle {
    border: solid 2px #ffc8d4 !important;
}

.ant-slider-track {
    background-color: #ffc8d4 !important;
}

.ant-input:hover {
    border-color: #001AEE !important;
}

.ant-input:focus {
    border-color: #001AEE !important;
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
}

.ant-btn-primary {
    background-color: #001AEE !important;
    border-color: #001AEE !important;
    color: #FFFFFF !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    border-color: #001AEE !important;
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
}

.ant-form-item-label>label {
    width: 12em;
    justify-content: end;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active {
    background-color: #001AEE !important;
    color: white !important;
}


.modal-add-sick-pig {
    .ant-modal-body {
        height: 500px;
        overflow: auto;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #001cee76;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #001cee18;
    }

    .ant-form-item-control-input-content {
        text-align: justify;
        text-justify: inter-word;
    }

}

.ant-modal-root {
    .ant-modal-wrap {

        .modal-add-sick-pig.modal-view {
            width: 1000px;
            max-width: 1000px;

            .ant-modal-body {
                background-color: #efefef;
            }

            .ant-form-item-required {
                font-size: 16px;
                font-weight: 700;
            }

            .ant-form-item-control-input {
                background-color: white;
                padding: 1em;
                border-radius: 10px;
            }
        }
    }

}

.select-symptom {
    width: 20em;

    .ant-select-selection-overflow {
        max-height: 84px;
        overflow: auto;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #001cee76;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #001cee18;
    }
}

.ant-space-item {
    cursor: pointer;
}

.ui-view-modal {
    height: 20px;
}

.temp-lst {
    display: flex;
    align-items: center;
    gap: 1em;

    .ant-input {
        max-width: 6em;
    }
}

.error {
    margin-top: .5em;
    color: red;
    font-size: 12px;
    font-weight: 500;
    transition: all .3s ease-in-out;
}