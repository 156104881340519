#pig {
    position: absolute;
    margin-top: 30px;
    right: 0;
    opacity: 50%;
}

#identity-confirmation {
    padding: 50px 0;
    background: #e8f8ff;

    #identity-confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        #identity-confirmation-title {
            line-height: 38px;
            font-size: 26px;
            color: #001AEE;
            font-weight: bold;
        }

        #identity-confirmation-btn {
            background-color: #00b1ff;
            color: #fff;
            height: 45px;
            font-size: 16px;
            margin: 32px 0;
        }
    }
}

#health-chart {
    // padding: 50px 0;

    #health-chart-content {
        display: flex;
        justify-content: space-between;

        #chart-label-color {
            display: flex;
            flex-direction: column;
            align-self: center;
        }

        #chart-label-color-1 {
            background-color: #364652;
            width: 10px;
            height: 10px;
            margin: 7px;
            border-radius: 50%;
        }

        #chart-label-color-2 {
            background-color: #ED7B84;
            width: 10px;
            height: 10px;
            margin: 7px;
            border-radius: 50%;
        }

        #chart-label {
            display: flex;
            flex-direction: column;
            align-self: center;
            margin-right: 20px;
            width: 160px;

            .doughnut-chart-labels {
                text-align: center;
                display: grid;
                // grid-gap: 12px;
                color: rgba(0, 0, 0, 0.45);
                justify-content: center;

                .doughnut-chart-title {
                    font-weight: 900;
                    display: flex;
                }

                p {
                    font-weight: bold;
                    color: #000;
                }

                #warning-update-identity {
                    color: red;
                    cursor: pointer;
                }
            }

            .doughnut-chart-labels2 {
                text-align: center;
                display: grid;
                // grid-gap: 12px;
                margin-top: 10%;
                color: rgba(0, 0, 0, 0.45);
                justify-content: center;

                .doughnut-chart-title {
                    font-weight: 900;
                    display: flex;
                }

                p {
                    font-weight: bold;
                    color: #000;
                }

                #warning-update-identity {
                    color: red;
                    cursor: pointer;
                }
            }
        }

        #poughnut-chart {
            width: 230px;
            height: 230px;
            margin-left: 69px;
        }
    }
}

#dashboard {
    width: 100%;
    padding: 2em 3em;
    // padding-top: 12em;
    background-color: #f3f3f3;

    #dashboard-content {
        width: 74%;
        margin: 0 auto;
        display: block;

        #dashboard-row-1 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 64px;

            .dashboard-information {
                display: grid;
                grid-gap: 24px;

                .dashboard-info-content {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    width: 100%;
                    height: 100%;

                    .info-icon {
                        margin-right: 1em;
                        width: 64px;

                        // img {
                        //     width: 100%;
                        //     height: 30px;
                        // }
                    }

                    .info-content {
                        display: flex;
                        flex-direction: column;
                        gap: 1em;
                        width: 100%;
                    }

                    .info-content.pen-content {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-template-rows: 1fr;

                        .info-data:nth-child(1) {
                            grid-area: 1 / 1 / 2 / 2;
                            align-items: center;
                            text-align: center;
                            justify-content: center;
                        }

                        .info-data:nth-child(2) {
                            grid-area: 1 / 2 / 2 / 3;
                            justify-content: center;
                        }

                        .info-data {
                            display: flex;
                            align-items: center;
                            gap: 1em;
                        }
                    }

                    .info-data {
                        cursor: pointer;

                        .data {
                            font-size: 24px;
                            font-weight: bold;
                        }

                        .label {
                            color: rgb(11, 11, 11);
                        }
                    }

                    .info-data.event {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }
            }
        }

        .dashboard-box {
            background: #FFFFFF;
            border-radius: 5px;
            padding: 3em;
            box-sizing: border-box;

            .chart-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 24px;

                h3 {
                    font-size: 22px;
                    font-weight: bold;
                }
            }
        }

        .dashboard-box-shadow {
            box-shadow: 0px 15px 40px 3px rgba(0, 0, 0, 0.16);
        }

        #dashboard-line-chart {
            margin-top: 124px;

            #line-chart {
                display: block;
                width: 90%;
                margin: 0 auto;
            }

            #year-select {
                border-radius: 10px;
                padding: 0 6px;
                box-sizing: border-box;
            }
        }
    }

    .warning-sick-pig {
        display: flex;
        align-items: center;
        justify-content: end;
        overflow: hidden;
        // margin-top: -10em;
        margin-bottom: 2em;
        margin-left: 2em;
        // margin: -10em 0 3em 10em;
        width: 90vw;
        background-color: white;
        border-radius: 4px;
        padding: 1em;
        // margin: -10em 10em 3em 10em;

        .scroll {
            white-space: nowrap;
            margin: 0 2em;

            div {
                display: flex;
                gap: 2em;
            }

            .RightToLeft {
                animation: RightToLeft 75s infinite linear;

                span {
                    font-size: 1.5em;
                    color: #001AEE;
                    margin-bottom: 0;

                    b {}
                }
            }

            .RightToLeft.one-warning {
                animation: RightToLeft 45s infinite linear;

                span {
                    font-size: 1.5em;
                    color: #001AEE;
                    margin-bottom: 0;

                    b {}
                }
            }

        }

    }

    .title-dashboard {
        position: relative;
        display: flex;
        align-items: center;
        gap: 1em;
        margin-bottom: 1em;

        .title-text {
            font-size: 1.75em;
            font-weight: bold;
        }

        .title-value {
            border-radius: 10px;
            padding: .5em 1em;
            background-color: white;
            font-size: 1.5em;
        }

    }

    .main-dashboard-content {
        display: grid;
        grid-template-columns: .75fr 1fr;
        grid-template-rows: .1fr 1fr;
        gap: 2em;

        .dashboard-content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 2em;

            .dashboard-content__item {
                display: flex;
                flex-direction: column;
                gap: 1em;
                background-color: white;
                border-radius: 10px;
                padding: .5em 2em;
                width: auto;
                position: relative;
                padding-bottom: 4em;

                .dashboard-content__item__title {
                    display: flex;
                    flex-direction: column;
                    font-size: 1.1em;
                    margin-bottom: 0em;
                }

                .title-right {
                    display: flex;
                    justify-content: end;
                    gap: 1em;
                    margin-top: 1em;

                    .ant-select {
                        width: 20em;
                        // margin-right: 2em;
                        text-align: start;
                    }

                    .ant-picker {
                        // width: 15em;
                    }
                }

                .dashboard-content__item__value {
                    font-size: 3em;
                    font-weight: bold;
                    position: absolute;
                    // left: 40%;
                    width: 83%;
                    text-align: center;
                    margin-right: 4em;
                    bottom: 0;

                }

                .dashboard-content__item__value.not {
                    font-size: 1.25em;
                    font-weight: bold;
                    position: absolute;
                    // left: 40%;
                    width: 83%;
                    text-align: center;
                    bottom: 10px;
                }

                .dashboard-content__item__value.value-top {
                    top: 1.5em;
                    bottom: auto;
                }

                .chart-dashboard {
                    width: 100%;
                    height: 150px;
                    margin-top: -5em;
                    position: relative;

                    .label {
                        position: absolute;
                        display: flex;
                        gap: 1em;
                        top: 0px;
                        justify-content: center;
                        width: 100%;
                        align-items: center;

                        .label-item {
                            display: flex;
                            align-items: center;
                            gap: 0.5em;

                            .label-color {
                                width: 16px;
                                height: 16px;
                                border-radius: 2px;
                            }

                            .label-name {
                                font-weight: 500;
                                font-size: 10px;
                                color: #858585;
                            }
                        }
                    }

                    .chart-type-pig {
                        margin-left: -8vw;
                        margin-top: -4vw;
                        width: 100px;
                    }

                    .legend-chart-pig {
                        position: absolute;
                        top: 80%;
                        right: 10%;
                        display: flex;
                        flex-direction: column;
                        // align-items: center;
                        gap: .5em;

                        .legend-chart-pig__item {
                            display: flex;
                            align-items: center;
                            gap: 1em;

                            .legend-chart-pig__item__color {
                                width: 16px;
                                height: 16px;
                                border-radius: 2px;
                            }

                            .legend-chart-pig__item__name {
                                font-weight: 500;
                                font-size: 10px;
                                color: #858585;
                            }
                        }
                    }

                    .legend-chart-pig.pig-abnormal {
                        top: 100%;
                    }

                    .chart-type-pig.pig-abnormal {
                        margin-top: -4vw;
                    }

                    // margin-bottom: 2em;
                }

                .not-found {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    height: 100%;
                    width: 100%;
                    font-size: 1.25em;
                    font-weight: bold;
                }
            }
        }

        .dashboard-content.content-1 {
            .dashboard-content__item:nth-child(1) {
                background-color: hsl(180, 48%, 90%) !important;
            }

            .dashboard-content__item:nth-child(2) {
                background-color: hsl(260, 100%, 90%) !important;
            }

            .dashboard-content__item:nth-child(3) {
                background-color: hsl(348, 100%, 90%) !important;
            }

            .dashboard-content__item:nth-child(4) {
                background-color: hsl(204, 82%, 90%) !important;
            }
        }

        .dashboard-content.content-2 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            gap: 2em;
        }

        .dashboard-content.content-3 {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;

            .dashboard-content__item {
                .dashboard-content__item__title {
                    font-size: 1.1em;
                    margin-bottom: 0em;
                    display: flex;
                    justify-content: space-between;
                }

                .chart-dashboard {
                    width: 100%;
                    height: 100%;
                    margin-top: 0em;
                    display: flex;
                    align-items: center;
                    position: relative;

                    // margin-bottom: 2em;
                    .title-label {
                        position: absolute;
                        bottom: 30px;
                        font-size: 10px;
                        left: 0;
                    }
                }

                // .chart-dashboard.chart-food {
                //     width: 100%;
                //     height: 100px;
                //     // margin-top: 0em;
                //     // margin-bottom: 2em;
                // }
            }
        }

    }

    // .main-dashboard-content.content-1 {
    //     display: grid;
    //     grid-template-columns: .75fr 1fr;
    //     grid-template-rows: 1fr;
    //     gap: 2em;
    // }
}

// .warning-sick-pig {
//     display: flex;
//     align-items: center;
//     justify-content: end;
//     overflow: hidden;
//     margin-top: -10em;
//     margin-bottom: 2em;
//     // margin: -10em 0 3em 10em;
//     width: 98vw;
//     // margin: -10em 10em 3em 10em;

//     .scroll {
//         white-space: nowrap;
//         margin: 0 2em;

//         div {
//             display: flex;
//             gap: 2em;
//         }

//         .RightToLeft {
//             animation: RightToLeft 75s infinite linear;

//             span {
//                 font-size: 1.5em;
//                 color: #001AEE;
//                 margin-bottom: 0;

//                 b {}
//             }
//         }

//         .RightToLeft.one-warning {
//             animation: RightToLeft 45s infinite linear;

//             span {
//                 font-size: 1.5em;
//                 color: #001AEE;
//                 margin-bottom: 0;

//                 b {}
//             }
//         }

//     }

// }


@keyframes RightToLeft {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-10%);
    }
}

@media (max-width: 4000px) {
    #dashboard {
        .title-dashboard {
            .title-text {}

            .title-value {}

        }

        .main-dashboard-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: .1fr 2.5fr;
            gap: 2em;

            .dashboard-content {
                .dashboard-content__item {
                    .dashboard-content__item__title {}

                    .dashboard-content__item__value {}

                    .dashboard-content__item__value.value-top {}

                    .chart-dashboard {}
                }
            }

            .dashboard-content.content-2 {}

            .dashboard-content.content-3 {

                .dashboard-content__item {
                    .chart-dashboard {}


                }
            }

        }
    }

}

@media (max-width: 2520px) {
    #dashboard {
        .title-dashboard {
            .title-text {}

            .title-value {}

        }

        .main-dashboard-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: .1fr 2.5fr;
            gap: 2em;

            .dashboard-content {
                .dashboard-content__item {
                    .dashboard-content__item__title {}

                    .dashboard-content__item__value {}

                    .dashboard-content__item__value.value-top {}

                    .chart-dashboard {}
                }
            }

            .dashboard-content.content-2 {}

            .dashboard-content.content-3 {

                .dashboard-content__item {
                    .chart-dashboard {}


                }
            }

        }
    }

}

@media (max-width: 1920px) {
    #dashboard {
        .title-dashboard {
            .title-text {}

            .title-value {}

        }

        .main-dashboard-content {
            display: grid;
            grid-template-columns: .9fr 1fr;
            grid-template-rows: 1fr 2fr;
            gap: 2em;

            .dashboard-content {
                .dashboard-content__item {
                    .dashboard-content__item__title {}

                    .dashboard-content__item__value {}

                    .dashboard-content__item__value.value-top {}

                    .chart-dashboard {
                        width: 350px;
                        margin-top: -7em;
                    }
                }
            }

            .dashboard-content.content-2 {}

            .dashboard-content.content-3 {

                .dashboard-content__item {
                    .chart-dashboard {}


                }
            }

        }
    }

}

@media (max-width: 1760px) {
    #dashboard {
        .title-dashboard {
            .title-text {}

            .title-value {}

        }

        .main-dashboard-content {
            display: grid;
            grid-template-columns: .9fr 1fr;
            grid-template-rows: 1fr 2fr;
            gap: 2em;

            .dashboard-content {


                .dashboard-content__item {


                    .dashboard-content__item__title {}

                    .dashboard-content__item__value {}

                    .dashboard-content__item__value.value-top {}

                    .chart-dashboard {
                        width: 350px;
                    }
                }
            }

            .dashboard-content.content-2 {}

            .dashboard-content.content-3 {

                .dashboard-content__item {
                    .chart-dashboard {}

                }
            }

        }
    }

}

@media (max-width: 1620px) {
    #dashboard {
        .title-dashboard {

            .title-text {}

            .title-value {}

        }

        .main-dashboard-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: .1fr 2fr;
            gap: 2em;

            .dashboard-content {
                .dashboard-content__item {


                    .dashboard-content__item__title {}

                    .dashboard-content__item__value {}

                    .dashboard-content__item__value.value-top {}

                    .chart-dashboard {}
                }


            }

            .dashboard-content.content-2 {
                .dashboard-content__item {
                    .dashboard-content__item__title {}

                    .dashboard-content__item__value {}

                    .dashboard-content__item__value.value-top {}

                    .chart-dashboard {
                        margin-top: -7em;
                    }
                }
            }

            .dashboard-content.content-3 {

                .dashboard-content__item {
                    .chart-dashboard {
                        width: 95%;
                    }


                }
            }

        }
    }

}

@media (max-width: 1420px) {
    #dashboard {
        .title-dashboard {


            .title-text {}

            .title-value {}

        }

        .main-dashboard-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: .1fr 1fr;
            gap: 2em;

            .dashboard-content {


                .dashboard-content__item {


                    .dashboard-content__item__title {}

                    .dashboard-content__item__value {}

                    .dashboard-content__item__value.value-top {}

                    .chart-dashboard {}
                }
            }

            .dashboard-content.content-2 {}

            .dashboard-content.content-3 {

                .dashboard-content__item {
                    .chart-dashboard {
                        width: 95%;
                    }


                }
            }

        }
    }

}

// .recharts-cartesian-axis .recharts-cartesian-axis-tick {
//     font-size: 1px;
//     /* Adjust the font size as needed */
// }

.classes-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.classes-modalContentCreate {
    background-color: white;
    width: 70%;
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
    background-color: #001AEE !important;
    color: white;
}

.chart-food {
    .recharts-legend-wrapper {
        display: none;
    }
}



.chart-food-picker {
    .ant-picker-content {
        thead {
            tr {
                th:nth-child(1) {
                    display: none;
                }
            }
        }
    }

    .ant-picker-cell-week {
        display: none;
    }
}