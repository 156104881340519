#pig-wrapper {
    width: 400px;
    height: 400px;
    right: 165px;
    top: calc(50% - 400px/2);
    border-radius: 50%;
    background: #001cee18;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    // position: relative;
}