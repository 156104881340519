.ant-modal-root {
    .ant-modal-mask {
        z-index: 790 !important;
    }

    .ant-modal-wrap {
        top: 10%;
        // max-width: 420px;
        max-height: fit-content !important;
        overflow: hidden;

        .ant-modal {
            // max-width: 420px;
            top: 0px;
            padding: 0px !important;

            .ant-modal-content {
                // max-width: 300px;
                max-height: 240px !important;

                .mainContent {
                    text-align: center;
                    font-family: 'Inter';
                    font-weight: bold;
                    padding: 10px 40px;

                    #subContent {
                        min-height: 80px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                    }

                    .footer {
                        padding: 10px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    #titleText {
                        color: #001cee18
                    }

                    .ant-btn-primary {
                        color: #fff;
                        border-color: #001cee18;
                        background: #001cee18;
                    }

                    .ant-btn-primary:hover,
                    .ant-btn-primary:focus {
                        color: #fff;
                        border-color: #001cee18;
                        background: #001cee18;
                    }

                    .ant-checkbox-checked .ant-checkbox-inner {
                        background-color: #001cee18;
                        border-color: #001cee18;
                    }

                    .ant-checkbox-wrapper {
                        align-self: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .ant-modal-root {
        .ant-modal-mask {}

        .ant-modal-wrap {
            top: 5%;

            .ant-modal {
                .ant-modal-content {
                    .mainContent {
                        #subContent {}

                        .footer {}

                        #titleText {}

                        .ant-btn-primary {}

                        .ant-btn-primary:hover,
                        .ant-btn-primary:focus {}

                        .ant-checkbox-checked .ant-checkbox-inner {}

                        .ant-checkbox-wrapper {}
                    }
                }
            }
        }
    }
}