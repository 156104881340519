#footer {
    width: 100%;
    background-color: #18191F;
    padding: 10px 0;
    display: block;
    position: relative;
    // margin-top: 50vh;
    bottom: 0;

    #footer-content {
        display: flex;
        justify-content: space-between;

        #left-footer {
            width: 50%;

            #footer-link {
                display: grid;
                grid-gap: 12px;
                margin: 12px 0;

                a {
                    color: #fff !important;
                    font-size: 14px;
                }

                #row-link {
                    color: #d9dbe1;

                    ul {
                        display: flex;

                        li {
                            display: inline-block;
                            margin-right: 24px;
                        }
                    }
                }

                .nav-menu-footer {
                    justify-content: unset !important;

                    .underline-menu {
                        display: inline-block;
                        padding: 16px 0px;
                        position: relative;
                    }
                }
            }

            #rights {
                color: #D9DBE1;
                font-size: 14px;
            }
        }

        #right-footer {
            #right-footer-title {
                font-size: 18px;
                color: #D9DBE1;
            }

            .download-link {
                width: 157px;
                margin-top: 18px;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media (max-width: 1920px) {
    #footer {
        #footer-content {

            #left-footer {

                #footer-link {
                    a {}

                    #row-link {
                        width: 1000px;

                        ul {

                            li {}
                        }
                    }

                    .nav-menu-footer {

                        .underline-menu {}
                    }
                }

                #rights {
                    width: 1000px;
                }
            }

            #right-footer {
                #right-footer-title {}

                .download-link {

                    img {}
                }
            }
        }
    }
}

@media (max-width: 1720px) {
    #footer {
        #footer-content {

            #left-footer {

                #footer-link {
                    a {}

                    #row-link {
                        // width: 1500px;

                        ul {

                            li {}
                        }
                    }

                    .nav-menu-footer {

                        .underline-menu {}
                    }
                }

                #rights {}
            }

            #right-footer {
                #right-footer-title {}

                .download-link {

                    img {}
                }
            }
        }
    }
}

@media (max-width: 1420px) {
    #footer {
        #footer-content {

            #left-footer {

                #footer-link {
                    a {}

                    #row-link {
                        width: 1000px;

                        ul {

                            li {}
                        }
                    }

                    .nav-menu-footer {

                        .underline-menu {}
                    }
                }

                #rights {
                    width: 1000px;
                }
            }

            #right-footer {
                #right-footer-title {}

                .download-link {

                    img {}
                }
            }
        }
    }
}