.profile-modal-main {
    padding: 1em;
    margin: auto 10vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: .1fr .1fr;
    gap: 3em;

    .container {
        position: relative;
        padding: 1.5em;
        background-color: #e8f8ff;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        border-radius: 10px;

        .container-title {
            font-size: 1.25em;
            font-weight: bold;
            margin-bottom: 1em;
            text-align: center;
        }

        .container-content {
            display: flex;
            flex-direction: column;
            gap: 1em;
            font-size: 1em;

            .container-content-sub-info {
                font-style: italic;
                color: rgb(95, 95, 95);
                margin-top: -1em;
            }

            .container-content-buttons {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                gap: 1em;

                .container-content-button {
                    border-radius: 10px;
                    cursor: pointer;
                }
            }

            .container-content-buttons.temp-track {
                position: absolute;
                bottom: 1.5em;
                left: -1%;
                // right: 25%;
                width: 100%;
            }

            .container-content-result {
                .container-content-result-title {
                    font-size: 1.25em;
                    font-weight: bold;
                    margin-bottom: 1em;
                }

                .container-content-result-content {
                    display: flex;
                    gap: 1em;
                    align-items: baseline;

                    .container-content-result-content-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 1em;

                        .title {
                            text-align: center;
                        }
                    }
                }
            }
        }

    }

}

.button-group {
    display: flex;
    gap: 1em;
}

.image-item {
    position: relative;



    .button-delete-image {
        position: absolute;
        z-index: 10000;
        font-size: 8px;
        padding: 0px 5px !important;
        top: 0px;
        right: 28px;
        border-radius: 50%;
        color: #ff6776;
        height: 25px;
    }


}

.modal-tracking {

    .modal-tracking-main {
        .modal-tracking-main-title {
            font-size: 1.25em;
            font-weight: bold;
            margin-bottom: 1em;
            text-align: center;
        }
    }

    .modal-tracking-main.tracking {
        .selected-images {
            overflow-y: scroll;
            max-height: 250px;
        }

        .selected-images.file {
            max-height: 300px;
        }

        .action-tracking {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1em;
        }

        .ant-form-item {
            margin-bottom: 20px;
            display: flex;
            align-items: baseline;
        }

        .ant-form-item:last-child {
            margin-bottom: 0;
        }
    }

    .ant-table-thead {
        tr {
            .ant-table-cell {
                text-align: center;
            }

            .ant-table-cell:nth-child(2) {
                width: 15%;
            }


            .ant-table-cell:nth-child(3) {
                width: 20%;
            }
        }
    }

    .ant-table-tbody {
        tr {
            .ant-table-cell {
                text-align: center;
            }
        }
    }
}

.custom-file-upload input[type="file"] {
    display: none !important;
}

.custom-file-upload span {
    padding: 10px 15px;
    background-color: #ff818e;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.custom-file-upload:hover span {
    background-color: #ff6776;
    scale: 1.1;
}

.selected-images {
    margin-top: 1em;
}

.image-container {
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
}

.select-farm {
    width: 20em !important;
}

.lst-btn-submit {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
}

.lst-image-tracking {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-height: 350px;
    overflow-y: scroll;

    .item-image {
        display: flex;
        align-items: center;
        gap: 1em;

        .image-tracking {
            border-radius: 10px;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        }
    }
}

.modal-delete {
    .modal-delete-buttons {
        display: flex;
        gap: 1em;
        justify-content: end;

    }
}

.form-edit-behaviour-tracking {
    .ant-form-item-label>label {
        margin-right: 2em;
    }

    .ant-input {
        // width: 10em;
    }
}

// .ant-spin-nested-loading {
//     z-index: 1000000;
// }
.table-image {
    .ant-table-thead {
        tr {
            .ant-table-cell {
                text-align: center;
            }

            .ant-table-cell:nth-child(2) {
                width: 1em;
            }
        }
    }

    .ant-pagination-options {
        display: none;
    }
}

.image-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .image-block {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.image-row {
    display: flex;
    justify-content: space-between;
}

.image-block {
    text-align: center;
}

.image {
    width: 100%;
    height: auto;
    align-content: center;
}

.image-name {
    margin-top: 10px;
    font-style: italic;
    font-size: 17px;
}

.table-detect {
    align-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: bottom;
    visibility: visible;
    margin-top: 0px;
    margin-bottom: auto;
    align-self: center;
    align-items: center;
    font-size: 15px;

    .table-detect-param {
        border: 1px solid;
        width: 100%;

        .table-detect-param-header {
            align-content: center;

            .headerGrid {
                border: 1px solid;
            }
        }

        .table-detect-param-body {
            align-content: center;

            .bodyGrid {
                border: 1px solid;
            }
        }
    }
}


td {
    text-align: center;
}

.table-model-name {
    font-style: italic;
    font-size: 17px;
    margin-bottom: 0px;
}

.ant-pagination-options {
    display: none;
}

@keyframes loading {
    0% {
        content: "Đang cập nhật.";
    }

    33% {
        content: "Đang cập nhật..";
    }

    66% {
        content: "Đang cập nhật...";
    }

    100% {
        content: "Đang cập nhật....";
    }
}

.loading {
    animation: loading 1s steps(4, end) infinite;
}

.pagination-number {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1em;
    margin-top: 0em;

    span {
        border: 1px solid #ff6776;
        color: #ff6776;
        padding: .5em 1em;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1em;
        font-weight: bold;
    }
}

.render-label {
    position: relative;
}

.render-label::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    pointer-events: none;
    /* Để không ảnh hưởng đến sự tương tác với nội dung trong modal */
}

.button-view-label {
    border-radius: 15px;
}